import React from 'react';
import './css/Supervisia.css';

function Supervisia() {
    return (
      <div className="Supervisia">

            <p>

                Предлагаю свои услуги индивидуального и группового супервизора в межмодальном подходе. Я - клинический психолог (2006г), аккредитованный супервизор ОППЛ (2022), если вам нужны накопительные часы и в ОППЛ для разных учебных программ, то это тоже все осуществляется. Ну и, конечно, выдаю справку о часах в индивидуальном формате.<br />
                <br />
                Супервизия возможна по разным вопросам вокруг особенностей личности, насилия, ПТСР, К ПТСР, работой с авторами насилия, с пострадавшими от насилия, работой с парами и семьями по разным вопросам.

            </p>
        
        </div>
    )
}

export default Supervisia;