import React from 'react';
import './css/NotWorkingWith.css';

function NotWorkingWith() {
    return (
        <div className="NotWorkingWith">

            <p>

                - Зависимость от ПАВ<br />
                - расстройство пищевого поведения<br />
                - острое горе<br />
                Я не готова работать с людьми, чья деятельность связана с военной, государственной, юридической или иной подобной тайной, которая может всплыть на консультации. Я не могу носить бремя подобных знаний. <br />

            </p>

        </div>
    )
}

export default NotWorkingWith;