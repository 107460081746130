import React from 'react';
import './css/WorkFormat.css';

function WorkFormat() {
    return (
        <div className="WorkFormat">
           
            <p>
                - Только онлайн через zoom, возможно созвониться в мессенджерах<br />
                Технические требования:<br />
                - быстрый интернет <br />
                •	Для видеовызовов 1 на 1:<br />
                •	для высококачественного видео: 600 кбит/с (исходящая/входящая);<br />
                •	для HD-видео с разрешением 720p: 1,2 Мбит/с (исходящая/входящая);<br />
                •	для HD-видео с разрешением 1080p: 3,8/3,0 Мбит/с (исходящая/входящая);<br />
                •	Для групповых видеовызовов:<br />
                •	для высококачественного видео: 1,0 Мбит/с / 600 кбит/с (исходящая/входящая);<br />
                •	для HD-видео с разрешением 720p: 2,6/1,8 Мбит/с (исходящая/входящая);<br />
                •	для HD-видео с разрешением 1080p: 3,8/3,0 Мбит/с (исходящая/входящая);<br />
                - гарнитура<br />
                - спокойное место, где вы одни. <br />
                - допускается выход на связь из припаркованной машины.<br />

            </p>

        </div>
    )
}


export default WorkFormat;