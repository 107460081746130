import React from 'react';
// import './css/WorkWith.css';

function WorkWith() {
    return (
        <div className="WorkWith">
            <p>

                <b>Подростки (с 14 лет):</b> <br />
                - агрессивное поведение, зачинщик буллинга и драк<br />
                - неуверенность в своих силах, тревожность<br />
                - депрессивные состояния, апатия, мысли о суициде<br />
                <br />
                <b>Взрослые (с 21 года):</b><br />
                - гнев, раздражение, абьюз, принесение разного вида ущерба в близких отношениях (по отношению к парнерше/парнеру, детям, пожилым взрослым, близким людям с особенностями)<br />
                - пострадавшие в ситуации домашнего насилия (физического, психоэмоционального)<br />
                - травмирующий опыт в прошлом (неострое состояние)<br />
                - посттравматическое стрессовое расстройство (ПТСР и КПТСР)<br />
                - разрыв отношений, развод<br />
                - сложности с построением достаточно надежных и стабильных романтических отношений<br />
                - сложности с построением достаточно надежных и стабильных отношений в рабочем коллективе<br />
                - проблема выбора в отношениях<br />
                - ситуация измены в отношениях<br />
                - неуверенность в своих силах, тревожность<br />
                - депрессивные состояния, апатия, мысли о суициде<br />
                - расстройства личности, биполярное расстройство, постпсихотическая поддержка<br />
                - патодиагностическое исследование<br />
                <br />
                <b>Семья/пары:</b><br />
                - кризис в отношениях, невозможность договориться, ссоры, конфликты, отчужденность<br />
                - межпоколенческие конфликты в семье (с детьми, с родителями и другими родственниками)<br />
                - ситуация измены, любовный треугольник<br />
                - повторный брак, сложности в отношениях с детьми партнера<br />
                - сложности в дружеских и коллегиальных отношениях<br />
                - партнер с особенностями личности, психиатрическим диагнозом<br />
                <br />
                <b>Группы:</b><br />
                - управление гневом<br />
                - саморегуляция и осознанность<br />
                - релакс-группа<br />
                <br />
                <b>Использую психотерапевтические подходы:</b><br />
                - системный семейный подход (ССТ)<br />
                - эмоционально-фокусированный подход (ЭФТ)<br />
                - когнитивно-поведенческий (КПТ)<br />
                - десенсибилизация и переработка травматичного опыта движением глаз (EMDR)<br />
                - нарративный подход<br />
                - терапия, фокусированная на переносе (ТФП)<br />
                - диалектическая поведенческая терапия (ДПТ)<br />
                - НОКСА-модель<br />

            </p>
        </div>
    )
}

export default WorkWith;