import React from 'react';
import './css/Training.css';

function Training() {
    return (
        <div className="Training">
            <p>
                Что мне даст тренинг навыков саморегуляции?<br />
                <br />
                На тренинге вы научитесь работать со своими переживаниями и регулировать свое эмоциональное состояние; узнаете о приемах антистрессового поведения и начнете применять их в жизни; попрактикуетесь в ненасильственном общении; поймете, как относиться к себе с сочувствием и добротой.<br />
                <br />
                Прохождение всех модулей тренинга приведет к существенным изменениям в понимании своих состояний и способности предотвращать собственные деструктивно-агрессивные или насильственные действия.<br />
                <br />
                Участие в тренинге хорошо сочетается с индивидуальной или групповой работой в рамках психологического консультирования или психокоррекции. Так как сам тренинг не предполагает исследование личных историй участников.<br />
                <br />
                тренинг построен на методах:<br />
                • диалектической поведенческой терапии,<br />
                • терапии, сфокусированная на сострадании,<br />
                • EMDR (ДПДГ) — десенсибилизации и переработке с помощью движений глаз.<br />
                <br />
                Как проходит тренинг?<br />
                Тренинг навыков саморегуляции — это еженедельные онлайн-встречи по 60-90 минут. Каждая встреча - это звено целой сети, хорошо бы не пропускать занятия внутри одного блока из 4 или 6 встреч, так как тренируемый навык формируется только при системной и стабильной практике.<br />
                <br />
                Всего — 8 модулей, каждый из которых разделен на 4 или 6 встреч.<br />
                Полный курс составляет 32 недели.<br />
                <br />
                Для получения максимального результата рекомендуется пройти все модули. Однако даем возможность выбрать то, что нужно именно вам. Участвуйте только в одном модуле, запишитесь на несколько любых или завершите весь курс. Решение за вами!<br />
                <br />
                К работе группы можно присоединиться только в начале очередного модуля. Количество участников неограниченно.<br />
                Тренинг также включает в себя выполнение домашних заданий. Они помогут освоить и закрепить нужные вам навыки немного быстрее.<br />
                <br />
                Но в этом процессе вы не останетесь без поддержки. Для каждого модуля работает приватный чат, где можно познакомиться с другими участниками (это необязательно), обсудить ваш прогресс и задать вопросы ведущим.<br />
                <br />
                <b>Программа тренинга:</b><br />
                <i><b>Модуль 1: Навыки осознанности - 4 встречи</b></i><br />
                Навыки осознанности изучаются всегда самыми первыми, так как являются базовыми звеньями, на которые потом нанизываются все остальные навыки, и они являются единственными, которые особенно выделяются на протяжении всех модулей. Как набор навыком практика осознанности представляет собой набор процесс целенаправленного наблюдения, описания и участия в реальности безоценочно, в одном моменте и эффективно. Навыки осознанности дают возможность соединиться со своими чувствами вместо подавления, избегания или попыток их изменить.<br />
                <br />
                Осознанность - это акт сознательного сосредоточения разума на настоящем моменте, безоценочно и без привязывания к происходящему. Мы можем противопоставить осознанность автоматическому, привычному или рутинному поведению; жесткому привязыванию к происходящему. Когда мы осознанны - мы открыты к изменчивости каждого момента по мере того, как оно появляется и исчезает; мы можем гибко реагировать на ситуации, ориентируясь не столько на кроткосрочные перспективы, но и на долгосрочные, на свои ценности и смыслы.<br />
                <br />
                <i><b>Модуль 2: Психофизиологическая саморегуляция - 4 встречи</b></i><br />
                Этот модуль является естественным продолжением навыкам осознанности, однако, кроме закрепления навыка наблюдения за своими чувствами и ощущениями, описания их, Вы освоите навыки медленного и глубокого дыхания, нервно-мышечной релаксации по Джекобсону, а также различные успокаивающие и балансирующие визуализации.<br />
                <br />
                Эти навыки пригодятся:<br />
                • в состоянии эмоционального напряжения — помогут «загасить» развитие напряжения и вернуть способность мыслить трезво;<br />
                • в сложных ситуациях — расширят диапазон вариантов поведения.<br />
                <br />
                <i><b>Модуль 3: Стрессоустойчивость - 4 встречи</b></i><br />
                Достижение стрессоустойчивости связано со способностью переносить и переживать кризисы (включая кризисы, связанные с зависимым поведением и проявлением агрессии), а также умением принимать жизнь такой, какая она есть в данный момент. Мы будем осваивать:<br />
                - Краткосрочные решения в болезненных ситуациях<br />
                - Навык воздерживаться от импульсивных действий<br />
                - Навык продумывать последствий импульсивных действий и бездействий в кризисной ситуации для принятия взвешенного решения<br />
                - Навык быстрого изменения состояния тела, чтобы противостоять эмоциональному возбуждению<br />
                <br />
                <i><b>Модуль 4: Эмоциональная саморегуляция - модуль из 6 занятий</b></i><br />
                Цель этого модуля - развить навыки осознанного понимания эмоций, что способствует их осмыслению и гибкости в отреагировании. А также, мы будем изучать, как выявить и предотвратить способы эмоционального избегания и неадаптивного поведения, связанного с сильными эмоциями, как повысить толерантность (терпимость) к сопровождающим эмоции физические ощущения.<br />
                Основные темы модуля:<br />
                - Понимание и обозначение эмоций<br />
                - Изменение нежелательных эмоций<br />
                - Снижение эмоциональной уязвимости<br />
                - Управление чрезмерными эмоциями<br />
                <br />
                <i><b>Модуль 5: Поведенческий анализ - 4 встречи</b></i><br />
                Вы научитесь анализировать неэффективное поведение и решать проблемы, связанные с ним, а также определять отсутствующее эффективное поведение.<br />
                Важная часть этого модуля — ведение дневниковых наблюдений.<br />
                Изменение поведения требует от нас понимания причин поведения, которое необходимо изменить. Анализ поведенческой цепочки ведет к пониманию последовательности событий, которые привели к конкретному поведению.<br />
                Любое поведение можно понимать как ряд взаимосвязанных составляющих. Эти звенья “связаны” вместе потому, что следуют одно за другим, одно звено в цепочке приводит к другому. В случае с привычным поведением может показаться, что эпизод невозможно разить на шаги, что “все происходит одновременно”. Подробный анализ предусматривает ряд вопросов для разделения этих звеньев, чтобы иметь возможность влиять на них и менять по необходимости.<br />
                <br />
                <i><b>Модуль 6: Любящая доброта, радость, невозмутимость и самосострадание - 6 встреч</b></i><br />
                Жемчужина всего курса - это навыки Мудрого сознания, вмещающие в себя проживание доброты, радости, невозмутимости и самосострадания. Возможно, это звучит забавно или несерьезно, но именно эти навыки помогают воспринимать жизненные ситуации из спокойного и уравновешенного состояния. Если человек многие годы или даже всю жизнь живет в состоянии эмоционального напряжения, импульсивен, испытывает вспышки гнева, то ему тяжело испытывать не только составляющие Мудрого разума, но и, в целом, испытывать положительные и приятные эмоции. Системная актуализация этих чувств и ощущений помогают почувствовать себя в большей безопасности и совершать поступки из спокойного и разумного состояния. Эти практики не имеют религиозного или эзотерического контекста, а направлены на актуализацию поддерживающих спокойствие и осознанность чувств и ощущений.<br />
                Вы узнаете, как концентрироваться на ощущениях благополучия, чтобы помочь себе и своему разуму почувствовать себя в безопасности.
                Этот модуль поможет вам:<br />
                • развить навыки сострадания,<br />
                • принять то, что трудности, боль и страдания являются частью человеческой жизни;<br />
                • понять, что у всех людей трудные события вызывают болезненные эмоции, а именно: страх, печаль, гнев, горе, стыд, вину,<br />
                • научиться обращаться с этими эмоциями с теплотой, добротой и заботой.<br />
                <br />
                <i><b>Модуль 7: Межличностная эффективность - 6 встреч</b></i><br />
                Важный модуль, где осваиваются навыки межличностной эффективности, которые направлены на достижение целей в случае, если отношения и самоуважение поддерживаются - это навыки уверенности в себе и решения проблем. Вторая часть навыков для того, чтобы создавать и поддерживать отношения - через сокращение социальной изоляции с помощью эффективного поиска друзей, формированию дружеских отношений, развития чуткости и межличностных навыков, необходимых для поддержания дружбы. И третья часть навыков - это навыки следования срединному пути, которые связаны с достижением равновесия принятия и изменения в отношениях.<br />
                В этом модуле вы освоите навыки ассертивности, научитесь сокращать социальную изоляцию, узнаете о способах выхода из деструктивных отношений.<br />
                А еще потренируетесь просить о том, что нужно, говорить «нет» и управлять межличностными конфликтами. Упражнения будут проходить в парах.<br />
                <br />
                <i><b>Модуль 8: Ненасильственное общение - 4 встречи</b></i><br />
                Вы научитесь обращать внимание на положительные стороны коммуникации и быть честным в самовыражении, а также освоите технику «Я-высказывание».<br />
                В основе модуля — идеи М. Розенберга о четырех составляющих процесса конструктивного общения:<br />
                - Наблюдения (описание конкретного действия, которое мы наблюдаем)<br />
                - Чувства (называние и описание своих чувств в моменте происходящего)<br />
                - Потребности (называние и описание потребностей, желаний, побуждений, нужд, которые возникают в процессе коммуникации)<br />
                - Просьба (формулирование обращения с опорой на понимание того, что происходит, с опорой на свои ценности и долгосрочные перспективы)<br />
                Упражнения будут проходить в парах.<br />

            </p>
        </div>

    )
}



export default Training;