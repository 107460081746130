function SelfTraining() {
    return (
        <div className="SelfTraining">
            <p>
                Тренинг навыков диалектической поведенческой терапии был разработан Маршей Линехан для людей с пограничным расстройством личности (ПРЛ). Во время тренинга участники обучаются справляться с такими состояниями, как сложности справляться со своими эмоциями, самоповреждения, рискованное поведение, вспышки гнева и действия насилия, суицидальное поведение, импульсивность в действиях.
                <br />
                <br />
                Опыт проведения тренинга навыков показал, что этим навыкам полезно обучаться всем группам людей с нарушениями настроения и сложностями в сфере общения с другими людьми.
                <br />
                <br />
                <b>Кому будет полезен этот тренинг:</b><br />
                •  Трудности с регуляцией эмоционального состояния<br />
                •  Сильные интенсивные эмоции<br />•  Импульсивное поведение вследствие сильных эмоций<br />
                •  Проявления насилия вследствие сильного гнева и беспомощности<br />
                •  Пограничное расстройство личности<br />
                •  Постравматическое стрессовое расстройство<br />
                •  комплексное постравматическое стрессовое расстройство<br />
                •  Химические и нехимические зависимости<br />
                <br />
                <b>Продолжительность тренинга:</b><br />
                1 год или 48 занятий.<br />
                <br />
                Это не является заменой психотерапии, и не является групповой психотерапией. Основная цель – освоить навыки саморегуляции и межличностного взаимодействия.<br />
                <br />
                <b>Формат тренинга:</b><br />
                Еженедельные встречи по 2,5 часа.<br />
                В течение первого часа происходит обсуждение домашнего задания и успешность применения навыков участниками в жизни. Далее следует перерыв (10-15 минут), после чего следует основная часть занятия.<br />
                <br />
                <b>Количество участников:</b><br />
                В группе от 8 до 12 человек.<br /><br />
                <b>Правила участия:</b><br />
                Участники допускаются на тренинг после собеседования.<br />
                В зависимости от случая, участие без индивидуальной терапии не представляется возможным.<br />
                Ваш индивидуальный терапевт должен быть информирован о вашем участии в тренинге и поддерживать контакт с тренерами группы.<br />
                <br />
                <b>Структура тренинга:</b><br />
                1.  Навыки осознанности. Позволяют тренировать внимание, учат снижать психическую вовлеченность в болезненные переживания. А также увеличить вовлеченность в радостные и приятные моменты.<br />
                2.  Навыки стрессоустойчивости. Помогают переживать кризисные ситуации, изменяя то, что возможно изменить, и радикально принимая то, что изменить нельзя.<br />
                3.  Навыки эмоциональной регуляции. Помогают снизить эмоциональную уязвимость. Обучают снижению интенсивности эмоций до переносимого уровня.<br />
                4.  Навыки межличностной эффективности. Помогают развивать умения взаимодействия с людьми.<br />
                {/* <br /><b><i>Стоимость - 2000 одно занятие, оплата осуществляется за месяц, пропуски не учитываются.</i></b><br /> */}
            </p>
        </div>

    )
}
export default SelfTraining;